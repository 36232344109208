import { RouteConfig } from 'vue-router'

const routeName = 'origination'

const pageNames = {
    VERIFY_IDENTITY: 'identity',
    IDENTITY_QUESTIONS: 'identity_questions',
    NOTARY_SCHEDULING: 'notary_scheduling',
    SIGN_HERE: 'sign_here',
    EXPERIAN_FROZEN: 'credit_report_frozen',
    // EXPERIAN_INSTANT_UNLOCK_RESULT must stay in sync w/ aven_backend/src/manager/experianInstantUnlockManager.ts
    EXPERIAN_INSTANT_UNLOCK_RESULT: 'instant_unlock_result',
    SCHEDULING_CONFIRMATION: 'scheduling_confirmation',
    NOTARY_KBA_START: 'kba',
    NOTARY_KBA_QUESTIONS: 'kba_questions',
    SESSION_COUNT_DOWN: 'session_count_down',
    REMOTE_NOTARIZATION_SESSION: 'notary_session',
    NOTARY_AV_PERMISSIONS: 'av_permissions',
    WELCOME: 'welcome',
    AUTO_WELCOME: 'auto_welcome',
    SCHEDULE_SUPPORT_CALL: 'schedule_support_call',
    AVEN_CARE: 'aven_care',
    AVEN_CARE_OVERVIEW: 'aven_care_overview',
    AVEN_CARE_EMPLOYMENT_ATTESTATION: 'aven_care_employment_attestation',
    PRE_SCHEDULING_DOCS: 'pre_scheduling_docs',
    // MOBILE_NOTARY_SCHEDULING
}

export const originationPagePaths = {
    VERIFY_IDENTITY: `/${routeName}/${pageNames.VERIFY_IDENTITY}`,
    IDENTITY_QUESTIONS: `/${routeName}/${pageNames.IDENTITY_QUESTIONS}`,
    AVEN_CARE: `/${routeName}/${pageNames.AVEN_CARE}`,
    SCHEDULING_CONFIRMATION: `/${routeName}/${pageNames.SCHEDULING_CONFIRMATION}`,
    NOTARY_SCHEDULING: `/${routeName}/${pageNames.NOTARY_SCHEDULING}`,
    SIGN_HERE: `/${routeName}/${pageNames.SIGN_HERE}`,
    EXPERIAN_FROZEN: `/${routeName}/${pageNames.EXPERIAN_FROZEN}`,
    EXPERIAN_INSTANT_UNLOCK_RESULT: `/${routeName}/${pageNames.EXPERIAN_INSTANT_UNLOCK_RESULT}`,
    NOTARY_AV_PERMISSIONS: `/${routeName}/${pageNames.NOTARY_AV_PERMISSIONS}`,
    NOTARY_KBA_START: `/${routeName}/${pageNames.NOTARY_KBA_START}`,
    NOTARY_KBA_QUESTIONS: `/${routeName}/${pageNames.NOTARY_KBA_QUESTIONS}`,
    SESSION_COUNT_DOWN: `/${routeName}/${pageNames.SESSION_COUNT_DOWN}`,
    REMOTE_NOTARIZATION_SESSION: `/${routeName}/${pageNames.REMOTE_NOTARIZATION_SESSION}`,
    WELCOME: `/${routeName}/${pageNames.WELCOME}`,
    AUTO_WELCOME: `/${routeName}/${pageNames.AUTO_WELCOME}`,
    SCHEDULE_SUPPORT_CALL: `/${routeName}/${pageNames.SCHEDULE_SUPPORT_CALL}`,
    AVEN_CARE_OVERVIEW: `/${routeName}/${pageNames.AVEN_CARE_OVERVIEW}`,
    AVEN_CARE_EMPLOYMENT_ATTESTATION: `/${routeName}/${pageNames.AVEN_CARE_EMPLOYMENT_ATTESTATION}`,

    // mobile notary origination paths
    PRE_SCHEDULING_DOCS: `/${routeName}/${pageNames.PRE_SCHEDULING_DOCS}`,
    // MOBILE_NOTARY_SCHEDULING
}

const dataCollectionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.VERIFY_IDENTITY,
        component: () => import('@/pages/origination/VerifyIdentity.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.IDENTITY_QUESTIONS,
        component: () => import('@/pages/origination/IdentityQuestions.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.EXPERIAN_FROZEN,
        component: () => import('@/pages/origination/ExperianFrozen.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.EXPERIAN_INSTANT_UNLOCK_RESULT,
        component: () => import('@/pages/origination/ExperianInstantUnlockResult.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.PRE_SCHEDULING_DOCS,
        component: () => import('@/pages/origination/PreSchedulingDocs.vue'),
        meta: { transitionName: 'slide' },
    },
]

const completionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.WELCOME,
        component: () => import('@/pages/origination/Welcome.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.AUTO_WELCOME,
        component: () => import('@/pages/origination/AutoWelcome.vue'),
        meta: { transitionName: 'slide' },
    },
]

const schedulingRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.NOTARY_SCHEDULING,
        component: () => import('@/pages/origination/NotaryScheduling.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.NOTARY_AV_PERMISSIONS,
        component: () => import('@/pages/origination/NotaryAVPermissions.vue'),
        meta: { transitionName: 'slide', public: true },
        props: true,
    },
    {
        path: originationPagePaths.AVEN_CARE,
        component: () => import(/* webpackChunkName: "origination" */ '../../../aven_notary/src/pages/origination/AvenCare.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SCHEDULING_CONFIRMATION,
        component: () => import('@/pages/origination/SchedulingConfirmation.vue'),
        meta: { transitionName: 'slide' },
    },
]

const remoteNotarizationRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.NOTARY_KBA_START,
        component: () => import('@/pages/origination/VerifyIdentity.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.NOTARY_KBA_QUESTIONS,
        component: () => import('@/pages/origination/IdentityQuestions.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SESSION_COUNT_DOWN,
        component: () => import('@/pages/origination/SessionCountDown.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.REMOTE_NOTARIZATION_SESSION,
        component: () => import('@/pages/origination/ApplicantNotarySession.vue'),
        meta: { transitionName: 'slide' },
    },
]

const supportRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.SCHEDULE_SUPPORT_CALL,
        component: () => import('@/pages/origination/SupportCallBooking.vue'),
        meta: { transitionName: 'slide' },
    },
]

const avenCareRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.AVEN_CARE_OVERVIEW,
        component: () => import('@/pages/debtProtection/AvenCareOverview.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.AVEN_CARE_EMPLOYMENT_ATTESTATION,
        component: () => import('@/pages/debtProtection/EmploymentAttestation.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const originationRoutes: RouteConfig[] = [...dataCollectionRoutes, ...completionRoutes, ...schedulingRoutes, ...remoteNotarizationRoutes, ...supportRoutes, ...avenCareRoutes]
